.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.typewriter h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}

@keyframes typingAnimation {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



.typing-animation-line {
  opacity: 0;
  animation: typingAnimation 0.5s ease-in-out forwards;
}

.typing-animation-line:nth-child(1) {
  animation-delay: 0.5s; /* Delay for the first line */
}

.typing-animation-line:nth-child(2) {
  animation-delay: 1.5s; /* Incremental delay for subsequent lines */
}
.typing-animation-line:nth-child(3) {
  animation-delay: 3.5s; /* Incremental delay for subsequent lines */
}
.typing-animation-line:nth-child(4) {
  animation-delay: 4.5s; /* Incremental delay for subsequent lines */
}

.typing-animation-line:nth-child(5) {
  animation-delay: 5.5s; /* Incremental delay for subsequent lines */
}

.typing-animation-line:nth-child(6) {
  animation-delay: 6.5s; /* Incremental delay for subsequent lines */
}

.typing-animation-line:nth-child(7) {
  animation-delay: 7.5s; /* Incremental delay for subsequent lines */
}
/* Add more rules for additional lines as needed */

.graph {
  background-image: url('./assets/art.png') !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}